import React, { useEffect, useState } from "react";
import axios from "axios";
import { InView } from 'react-intersection-observer';
import { motion } from "framer-motion";

export default function Bio() {
    const [photo, setPhoto] = useState("");
    const [data, setData] = useState("");

    useEffect(
        () => {
            axios(window.URL_PREFIX + "/api/singletons/get/Description?token=" + window.TOKEN)
            .then((res) =>{
                setData(res.data.Description);

                if(window.innerWidth<940){
                    setPhoto(window.URL_PREFIX + "/api/cockpit/image?token=" + window.TOKEN + "&src=" + res.data.Photo.path + "&mime=image/webp&w=" + window.innerWidth/100*90 + "&h=" + window.innerHeight/2 + "&o=1");
                }else if(window.innerWidth<1240){
                    setPhoto(window.URL_PREFIX + "/api/cockpit/image?token=" + window.TOKEN + "&src=" + res.data.Photo.path + "&mime=image/webp&w=" + window.innerWidth/2 + "&h=" + window.innerHeight/2 + "&o=1");
                }else{
                setPhoto(window.URL_PREFIX + "/api/cockpit/image?token=" + window.TOKEN + "&src=" + res.data.Photo.path + "&mime=image/webp&w=" + window.innerWidth/5 + "&h=" + window.innerHeight/2 + "&o=1");
                }
            })
    }, []
    );

    return (
        <>
            <div className="content">
            <InView threshold={0.25} triggerOnce={true}>
                {({ ref, inView }) => (
                    <motion.h2
                        ref={ref}
                        initial={{opacity: 0}}
                            animate={inView ? {opacity: 1}: {opacity: 0}}
                            transition={{duration: 0.75}}>
                        Bio
                    </motion.h2>
                )}
            </InView>

            <InView threshold={0.25} triggerOnce={true}>
                {({ ref, inView }) => (
                    <motion.img
                        ref={ref}
                        initial={{opacity: 0}}
                        animate={inView ? {opacity: 1}: {opacity: 0}}
                        transition={{duration: 0.75}}
                        src={photo}
                        id="bioPhoto" />
                )}
            </InView>

            {data.split("\n").map((el, idx) =>
                <InView threshold={0.25} triggerOnce={true} key={idx}>
                    {({ ref, inView }) => (
                        <motion.p
                            ref={ref}
                            initial={{ opacity: 0 }}
                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                            transition={{ duration: 0.75 }}>
                            {el}
                        </motion.p>
                    )}
                </InView>
            )}

</div>
        </>
    )
}