import React from "react";
import $ from "jquery";
import { InView } from 'react-intersection-observer';
import { motion } from "framer-motion";

export default function Contact() {
    const postForm = (e) => {
        e.preventDefault();
        fetch(window.URL_PREFIX + "/api/forms/submit/Contact?token=" + window.TOKEN, {
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                form: {
                    email: $("#email").val(),
                    text: $("#text").val()
                }
            }),
        }).then(() => {
            $("#email").val("");
            $("#text").val("");
            alert("Your message is sent.");
        })
    }

    return (
        <>
            <div className="content">
                <InView threshold={0.25} triggerOnce={true}>
                    {({ ref, inView }) => (
                        <motion.div
                            ref={ref}
                            initial={{ opacity: 0 }}
                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                            transition={{ duration: 0.75 }}>
                            <h2>Contact</h2>
                            <form onSubmit={postForm}>
                                <input type="email" placeholder="Email..." id="email" required />
                                <textarea placeholder="Ecrire quelque chose..." rows={5} id="text" required></textarea>
                                <input type="submit" value="Send" />
                            </form>
                        </motion.div>
                    )}
                </InView>
            </div>
        </>
    )
}