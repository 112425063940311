import React, { useState, useEffect } from "react";
import $ from "jquery";
import { InView } from 'react-intersection-observer';
import { motion } from "framer-motion";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { ReactComponent as LogoYoutube } from "../components/css/LogoYoutube.svg";
import { ReactComponent as LogoSoundCloud } from "../components/css/LogoSoundCloud.svg";

export default function Medias() {
    const location = useLocation();
    const [photos, setPhotos] = useState([]);

    const constructImagesLink = (res, width, height)=>{
        let images = []
                    res.map((im) => images = [...images, window.URL_PREFIX + "/api/cockpit/image?token=" + window.TOKEN + "&src=" + im._id + "&mime=image/webp&w=" + width + "&h=" + height + "&o=1"]);
                    return images;
    }
    useEffect(() => {
        axios(window.URL_PREFIX + "/api/cockpit/assets?token=" + window.TOKEN)
            .then(
                (res) => res.data.assets
            ).then(
                (res) => {
                    if(window.innerWidth<940){
                        return constructImagesLink(res, $(".content").width()/100*90, $(".content").width()/100*90);
                    }else if(window.innerWidth<1240){
                        return constructImagesLink(res, $(".content").width() / 2, $(".content").width() / 2); 
                    }else{
                        return constructImagesLink(res, $(".content").width() / 3, $(".content").width() / 3);
                    }
                })
            .then((images) => {
                setPhotos(images);
            })
    }, []);

    return (
        <>
            <div className="content">
                <InView threshold={0.25} triggerOnce={true}>
                    {({ ref, inView }) => (
                        <motion.h2
                            ref={ref}
                            initial={{ opacity: 0 }}
                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                            transition={{ duration: 0.75 }}>
                            Links
                        </motion.h2>
                    )}
                </InView>

                <div className='mediaLink'>
                    <InView
                    threshold={0.25} 
                    triggerOnce={true}>
                        {({ ref, inView }) => (
                        <motion.a
                        href="https://www.youtube.com/channel/UCe2f3fT0NlW54WBFsHhiFTg"
                        alt="Youtube"
                        title="Youtube"
                        target="_blank"
                        rel="noreferrer"
                        ref={ref}
                        initial={{ opacity: 0 }}
                        animate={inView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 0.75 }}>
                            <LogoYoutube />
                        </motion.a>
                        )}
                    </InView>
                    <InView
                    threshold={0.25}
                    triggerOnce={true}>
                        {({ ref, inView }) => (
                        <motion.a
                        href="https://soundcloud.com/apolline-jesupret"
                        alt="SoundCloud"
                        title="SoundCloud"
                        target="_blank"
                        rel="noreferrer"
                        ref={ref}
                        initial={{ opacity: 0 }}
                        animate={inView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 0.75 }}>
                            <LogoSoundCloud />
                        </motion.a>
                        )}
                    </InView>
                </div>
                {location.pathname !== "/" ?
                <>
                <InView threshold={0.25} triggerOnce={true}>
                    {({ ref, inView }) => (
                        <motion.h2
                            ref={ref}
                            initial={{ opacity: 0 }}
                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                            transition={{ duration: 0.75 }}>
                            Gallery
                        </motion.h2>
                    )}
                </InView>

                <div className='mediaPhoto'>
                    {photos.map((photo, idx) =>
                        <InView threshold={0.25} triggerOnce={true} key={idx}>
                            {({ ref, inView }) => (
                                <motion.img
                                    src={photo}
                                    className="media"
                                    layout='fill'
                                    ref={ref}
                                    initial={{ opacity: 0 }}
                                    animate={inView ? { opacity: 1 } : { opacity: 0 }}
                                    transition={{ duration: 0.75 }} />
                            )}
                        </InView>
                    )}
                </div>
                </>
                : null}
            </div>
        </>
    )
}