import React, { useEffect, useState } from "react";
import $ from "jquery";

import Bio from "./Bio";
import Medias from "./Medias";
import Contact from "./Contact";
import axios from "axios";

import { motion } from "framer-motion";

export default function Home() {
    const [next_events, setNextEvents] = useState([]);

    useEffect(()=>{
        axios(window.URL_PREFIX + "/api/singletons/get/Home?token=" + window.TOKEN).then((res)=>{
            $("header").css("background-image", "url("+window.URL_PREFIX + "/api/cockpit/image?token=" + window.TOKEN + "&src=" + res.data.Background.path + "&mime=image/webp&w=" + window.innerWidth*2 + "&h=" + window.innerHeight*2 + "&o=1)");
        })

        axios(window.URL_PREFIX + "/api/collections/get/next_events").then((res) => {
            setNextEvents(res.data.entries)
        })
        
    }, []);

    console.log(next_events)
    return (
        <>
        <header>
            <motion.h1
                            initial={{ opacity: 0, scale: 0}}
                            animate={{ opacity: 1, scale: 1}}
                            transition={{ duration: 1, delay: 1}}>
                                Apolline Jesupret
            </motion.h1>
            {window.innerWidth>940 ?
            <motion.div target="_blank"
            initial={{ opacity: 0, scale: 0}}
            animate={{ opacity: 1, scale: 1}}
            transition={{ duration: 1, delay: 1}}>
                <span>Next Events</span>
            {next_events.map((next_event, key)=>
                <a
                key={key}
            href={next_event.link}
            target="_blank">
                {new Date(next_event.date).getDate().toString().padStart(2, "0")}-{(new Date(next_event.date).getMonth()+1).toString().padStart(2, "0")}-{new Date(next_event.date).getFullYear()} - {next_event.place}<br />{next_event.name}
            </a>
            )}
            </motion.div>
: null}
        </header>
            <Bio />
            <Medias />
            <Contact />
        </>
    )
}