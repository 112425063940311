import React, { useEffect, useState } from "react";
import { InView } from 'react-intersection-observer';
import { motion } from "framer-motion";
import axios from "axios";

export default function Catalogue() {
    const [catalogueData, setCatalogueData] = useState([]);
    let currentYear = 0;

    useEffect(() => {
        axios(window.URL_PREFIX + "/api/collections/get/Catalogue").then((res) => {
            setCatalogueData(res.data.entries)
        })
    }, [setCatalogueData]);
    return (
        <div className="content">
            <InView threshold={0.25} triggerOnce={true}>
                {({ ref, inView }) => (
                    <motion.h2
                        ref={ref}
                        initial={{ opacity: 0 }}
                        animate={inView ? { opacity: 1 } : { opacity: 0 }}
                        transition={{ duration: 0.75 }}>
                        Catalogue
                    </motion.h2>
                )}
            </InView>

            <div className="catalogueEntries">
                {
                    catalogueData.map((catal, id) => {
                        let date = new Date(catal.Date);
                        if (currentYear !== date.getFullYear()) {
                            currentYear = date.getFullYear();
                            return (
                                <InView threshold={0.25} key={id} triggerOnce={true}>
                                    {({ ref, inView }) => (
                                        <motion.div
                                            ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                                            transition={{ duration: 0.75 }}>
                                            <h3>{date.getFullYear()}</h3>
                                            <a href={catal.Lien} target="_blank" rel="noreferrer">{catal.Titre}</a>
                                            {catal.Instruments ? catal.Instruments.map((el, idInst)=><span key={idInst}>{el}</span>):null}
                                        </motion.div>
                                    )}
                                </InView>
                            );
                        } else {
                            return (
                                <InView threshold={0.25} key={id} triggerOnce={true}>
                                    {({ ref, inView }) => (
                                        <motion.div
                                            ref={ref}
                                            initial={{ opacity: 0 }}
                                            animate={inView ? { opacity: 1 } : { opacity: 0 }}
                                            transition={{ duration: 0.75 }}>
                                            <a href={catal.Lien} target="_blank" rel="noreferrer">{catal.Titre}</a>
                                            {catal.Instruments ? catal.Instruments.map((el, idInst)=><span key={idInst}>{el}</span>):null}
                                        </motion.div>
                                    )}
                                </InView>
                            );
                        }
                    })
                }
            </div>
        </div>
    )
}