import { Routes, Route } from "react-router-dom";

import NavBar from "./components/NavBar";

import Home from "./pages/Home";
import Bio from "./pages/Bio";
import Catalogue from "./pages/Catalogue";
import Medias from "./pages/Medias";
import Contact from "./pages/Contact";

export default function App(){
    return(
        <>
        <NavBar />
    <Routes>
        <Route index element={<Home />} />
        <Route path="biography" element={<Bio />} />
        <Route path="catalogue" element={<Catalogue />} />
        <Route path="medias" element={<Medias />} />
        <Route path="contact" element={<Contact />} />
    </Routes>
    </>
    );
}