import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import $ from "jquery";

import "./css/NavBar.css";

const menu = [
    { title: 'Home', path: '/#' },
    { title: 'Bio', path: '/biography/#' },
    { title: 'Catalogue', path: '/catalogue/#' },
    { title: 'Medias', path: '/medias/#' },
    {title: 'Contact', path: '/contact/#'}
  ]

  export default function NavBar(){
    const location = useLocation();

    const navBarListenerFunc = ()=>{
      if(window.innerHeight < window.scrollY+$("nav").height()){
        $("nav").css("background-color", "rgba(47, 79, 79, 1)");
        $("#goToTop").addClass("showGoToTop");
      }else if(!$("nav").hasClass("open")){
        $("nav").css("background-color", "rgba(47, 79, 79, 0.5)");
        $("#goToTop").removeClass("showGoToTop");
      }else{
        $("nav").css("background-color", "rgba(47, 79, 79, 1)");
      }
    }

    useEffect(()=>{
      $("#opener").on("click", ()=>{
        $("nav:not(.open) #opener").html("&#10005;");
        $("nav.open #opener").html("Menu");
        $("nav").toggleClass("open");
        navBarListenerFunc();
      })
    }, [])

    useEffect(()=>{
      window.scrollTo(0, 0);
      if(location.pathname === "/"){
        navBarListenerFunc();
      window.addEventListener('scroll', navBarListenerFunc);

      return ()=> window.removeEventListener("scroll", navBarListenerFunc);
    }else{
      $("nav").css("background-color", "rgba(47, 79, 79, 1)");
    }
    }, [location])
      return(
        <>
          <nav>
          <button id="opener">Menu</button>
        {   
            menu.map((el)=><NavLink key={el.title} to={el.path} className={(navData) => navData.isActive ? "active" : ""} onClick={()=>{$("nav").removeClass("open"); $("nav #opener").html("Menu");}}>{el.title}</NavLink>)

        }
        </nav>
        <a href='/#' id="goToTop">&#709;</a>
        </>
      );
  }